import { createApi } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { ApiResponseHandler } from "../../Functions/ResponseHandler";
import { AllCookiesName } from "../../Imports/CookiesName";
import { UserLogOut } from "../Users/UsersSlice";

const TemplateManagerTags = [
  "Template-Manager-Types",
  "Template-Manager-Section",
  "Template-Manager-Template",
];
const AdminTemplateManagetTags = [
  "Admin-Template-Manager-Template",
  "Admin-Template-Manager-Types",
];
const AuthTags = ["Auth-User", "Auth-Student"];
const SuperTags = ["Super-Colleges", "superEditList"];
const StudentTags = ["student_leave", "student_otp"];
const SetupTags = [
  "Admin-Setup-Class",
  "Admin-Setup-Section",
  "Admin-Setup-ClassSection",
  "Admin-Setup-Houses",
  "Admin-Setup-Banks",
  "Admin-Setup-Branches",
  "Admin-Setup-Session",
  "Admin-Setup-Group",
  "Admin-Setup-Setting",
  "Admin-Setup-Hostel-Block",
  "Admin-Setup-Hostel-Room",
  "Admin-Setup-Hostel-RoomType",
  "Admission-Circle",
  "Admission-Bus-Routes",
  "Admission-Stops",
  "Admission-Route-Stops",
  "Documents",
  "Setup-Subject",
  "Setup-Leave-Remark",
  "Setup-Subject-Map",
  "Setup-Class-Section-Subject",
  "Setup-Designation-Name",
  "Admission-No-Format",
  "ClassSectionSubjectList",
  "configuration-type-value",
  "users-teacher",
  /////////CRMS tags/////////////////
  "Admin-Setup-Course",
  "Admin-Setup-Semester",
  "Admin-Setup-institute-section",
  "Admin-Setup-institute-Group",
  "Admin-Setup-Course-Semester",
  "Admin-Setup-CSSGroup",
  "Admin-Setup-SubjectCRMS",
  "Admin-Setup-Course-Batch",
  "Admin-Setup-Category",
  "Admin-Setup-CourseCategory",
  "Admin-Setup-Document",
  "Admin-Setup-Faculty-Subject",
];
const WebManagerTags = [
  "AdminWebCategory",
  "AdminTemplate",
  "AdminTemplatePage",
  "AdminTemplateSection",
  "AdminTemplateStatic",
  "AdminWebPage",
  "AdminMenuManager",
  "AdminCollegeTemplate",
];
const resultTags = [
  "Admin-result-term",
  "Admin-result-settings",
  "Admin-Result-Grade",
  "Admin-result-marksheet",
  "Admin-result-student-sub",
  "Admin-result-attachment",
  "Admin-result-Board-RollNo",
  "Admin-result-des-indicator",
  "Admin-result-term-subject-setting",
  "Admin-result-remark-list",
  "Admin-result-marks-entry-student",
  "Admin-result-remark-Field",
  "Admin-result-freeze-term",
  "Admin-result-marksheet-final-setting",
  "Admin-result-promotion-demotion",
  //------------------- new tags
  "Admin-result-marks-entery-student-subjectwise",
];

const WebCms = [
  "WebCms-News",
  "WebCms-Category-News",
  "WebCms-Events",
  "WebCms-Category-Events",
  "WebCms-Circular",
  "WebCms-Category-Circular",
  "WebCms-Photo-Gallery",
  "WebCms-Category-Photo-Gallery",
  "WebCms-Video-Gallery",
  "WebCms-Category-Video-Gallery",
  "WebCms-Slider",
  "WebCms-Category-Slider",
  "WebCms-Publication",
  "WebCms-Category-Publication",
  "WebCms-Media-Press",
  "WebCms-Category-Media-Press",
  "WebCms-Bulletin-Board",
  "WebCms-Category-Bulletin-Board",
  "WebCms-Thought-Of-The-Day",
  "WebCms-Award-And-Achievement",
];
const libraryTags = [
  "Library-Settings-DDC",
  "Library-Settings-Streams",
  "Library-Settings-Sub-Streams",
  "Library-Settings-Supplier",
  "Library-Settings-Head",
  "Library-Settings-IssueGroup",
  "Library-Settings-Almirah",
  "Library-Settings-Periodical",
  "Library-Recommendation",
  "Library-Binding",
  "Library-Lost-Damage",
  "Library-ItemPositioning",
  "Sub-Streams",
  "Library-Periodical-Bill",
  "Library-Book-Media-Purchase",
  "Periodical-Receiving",
  "Library-Periodical-Receiving",
  "Library-Periodical-Receiving-Receipt-Date",
  "Library-Periodical-Receipt-No-Receipt-Date",
  "Library-Periodical-Periodicals-From-Type",
  "Library-Periodical-Periodicity-From-Receipt-No",
  "Library-AccessionNumber",
  "Library-NewEntry-Head",
  "Library-Advance-Booking",
  "Library-Issue-Return",
  "Library-Stock-Transfer",
  "Library-Stock-Verification",
  "Library-Stock-Verification-Report",
  "Library-Pass",
];
const LessonPlanningTags = [
  "LP-Settings-Lesson-Creation",
  "LP-Settings-Lesson-Planner",
  "LP-lesson-status",
];
const payrollTags = [
  "allowances-name",
  "allowances-structure",
  "deduction-name",
  "remittance-name",
  "department",
  "payScale",
  "deduction-structure",
  "remittance-structure",
  "designation",
  "employee-info",
];
const paymentTags = ["Admin-payment-integration-status"];

const staffTags = [
  "admin-financial-session-setup",
  "staff-attendance-logs",
  "staff-attendance-wfh",
  "location-geofencing",
  "employeesShift",
  "staff-regularize-requests",
];
const holidayTags = ["holiday-group"];
const visitorManagement = [
  "Vistor-Management-Reason",
  "Scheduled-Form",
  "Bulk-Import-Log",
  "Visitor-time-track",
];

const registration = [
  "criteria-name",
  "Admin-criteria",
  "Admin-registration-rules",
  "Admin-registration-fee",
  "student-registration",
  "Admin-registration-instruction",
];
const admission = [
  "Admission-Student-info",
  "Admission-SLC",
  "Admission-Strength",
  "Admission-section-from-class",
  "Admission-stope-from-circle-and-route",
  "Admission-Student-info-delete-form",
  "Admission-global-filter",
  "Admission-Student-image-get",
  "Admission-Student-subjects",
];

const whatsAppTemplate = ["Create_Template"];

const axiosBaseQuery =
  ({ baseUrl }) =>
  async (payload, api) => {
    let state = api.getState();
    const response = await ApiResponseHandler({
      method: payload.method,
      endPoint: payload.url,
      data: payload.body,
      auth: Cookies.get(AllCookiesName.accessToken) || "",
      msz: payload.msz,
      image: payload.image,
      contentType: payload.contentType,
      upPercent: payload.upPercent,
      doPercent: payload.doPercent,
      skipError: payload.skipError,
      skipToken: payload.skipToken,
      recaptcha: payload?.recaptcha,
      academicSession: !payload.skipAcademicSession
        ? state.comps?.academicSession?.value || ""
        : "",
      refreshData: {
        method: "POST",
        endPoint: "user/refreshToken",
        skipToken: true,
        data: {
          deviceId: Cookies.get(AllCookiesName.token),
          refreshToken: Cookies.get(AllCookiesName.refreshToken) || "",
          userName: Cookies.get(AllCookiesName.userName),
        },
      },
    });
    if (response.res) {
      return { data: response.data };
    } else if (response.out) {
      Cookies.remove(AllCookiesName.refreshToken);
      Cookies.remove(AllCookiesName.accessToken);
      Cookies.remove(AllCookiesName.userName);
      api.dispatch(UserLogOut());
      return { error: response.data };
    } else {
      return { error: response.data };
    }
  };

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({ baseUrl: "" }),
  tagTypes: [
    ...AuthTags,
    ...SuperTags,
    ...StudentTags,
    ...SetupTags,
    ...resultTags,
    ...WebManagerTags,
    ...WebCms,
    ...libraryTags,
    ...LessonPlanningTags,
    ...payrollTags,
    ...paymentTags,
    ...visitorManagement,
    ...TemplateManagerTags,
    ...AdminTemplateManagetTags,
    ...registration,
    ...whatsAppTemplate,
    ...staffTags,
    ...holidayTags,
    ...admission,
    "Admin-ManageUser",
    "Admin-Fee-Group",
    "Admin-Fee-Installment",
    "Admin-Fee-Component",
    "Admin-Fee-FineName",
    "Admin-Fee-Structure",
    "Admin-Fee-Cheque",
    "Admin-Fee-Concession-Name",
    "Admin-Fee-Concession-Structure",
    "Admin-Fee-DailyFeeGet",
    "Admin-Fee-DailyFeeTrans",
    "Admin-StudentFee-Structure",
    "Admin-Misc-StudentWise",
    "Admin-Fee-Refund",
    "Admin-Fee-RefundTrans",
    "Admin-excess-fee",
    "Admin-Fee-Wallet",
    "Admin-ManageUser-details",
    "Admin-ManageUser-Rights",
    "Admin-SMS-Template",
    "Attendance-Report-Consolidate",
    "Attendance-Class-Consolidate-Count",
    "Attendance-Student-Leave",
    "Attendance-Student-Listing",
    "Leave-Listing",
    "Admin-Student-SMS",
    "Admin-SMS-Log",
    "Admin-Group-SMS",
    "installment",
    "student_modified",
    "Attandance-for-SLC",
    "userLog",
    "Academic-CMS",
    "Annual-broadcast",
    "WebCms-News",
    "WebCms-Events",
    "WebCms-Category-Events",
    "WebCms-Circular",
    "WebCms-Category-Circular",
    "WebCms-Gallery",
    "WebCms-Category-Gallery",
    "WebCms-Video-Gallery",
    "WebCms-Category-Video-Gallery",
    "WebCms-Publication",
    "WebCms-Category-Publication",
    "WebCms-Media-Press",
    "WebCms-Category-Media-Press",
    "WebCms-Bulletin-Board",
    "WebCms-Category-Bulletin-Board",
    "WebCms-Thought-Of-The-Day",
    "WebCms-Award-And-Achievement",
    "AcademicCMS-user-list",
    "AcademicCMS-Homework-Report",
    "AcademicCMS-Homework-List",
    "Admin-Notification-Log",
    "Sibling-Detail-Delete",
    "Auth-User-registration",
    "Super-Admin-Other-Setting",
    "Super-Admin-Setting-Type",
  ],
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: 60,
  refetchOnFocus: false,
  refetchOnReconnect: true,
  endpoints: (builder) => ({}),
});
